import React from "react";
import Layout from "./src/layouts";
import { StoreProvider } from "./src/context/storeContext";
import "./src/styles/globals.css";
import "./src/assets/scss/style.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Toaster } from "react-hot-toast";
export const wrapPageElement = ({ element, props }) => {
  return (
    <StoreProvider>
      <Layout {...props}>{element}</Layout>;
      <Toaster />
    </StoreProvider>
  );
};
