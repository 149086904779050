import React, { Fragment, useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import ImageView from "../components/ImageView";
import VideoPopup from "../components/VideoPopup";
import { animation } from "../utils";
import Footer from "./Footer";
import Header from "./Header";
import ScrollTop from "./ScrollTop";
import PreLoader from "./PreLoader";

const Layout = ({ children, header, footer }) => {
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    animation();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setLoader(false), 2000);
    return () => clearTimeout(timer);
  });
  const { sanitySitesettings, allSanityBlog } = useStaticQuery(
    graphql`
      query {
        sanitySitesettings {
          topBanner {
            enable
            welcome
            time
            social {
              icon
              url
            }
          }
          header {
            logo {
              alt
              asset {
                gatsbyImageData(
                  formats: WEBP
                  fit: FILLMAX
                  placeholder: BLURRED
                )
              }
            }
            logo2 {
              alt
              asset {
                gatsbyImageData(
                  fit: FILLMAX
                  formats: WEBP
                  placeholder: BLURRED
                )
              }
            }
            button {
              title
              linkType
              link
              btnType
            }
            menu {
              parent {
                label
                link
                type
              }
              childMenu {
                type
                link
                label
              }
            }
          }
          footer {
            logo {
              alt
              asset {
                gatsbyImageData(fit: FILLMAX, placeholder: DOMINANT_COLOR)
              }
            }
            about
            email
            location
            _rawCc
            menu {
              label
              link
              type
            }
            newsletter {
              enable
              heading
              button {
                btnType
                link
                linkType
                title
              }
            }
            social {
              icon
              url
            }
          }
        }
        allSanityBlog(limit: 2, sort: { _createdAt: DESC }) {
          edges {
            node {
              title
              slug {
                current
              }
              thumbnail {
                alt
                asset {
                  url
                }
              }
              publishedAt
            }
          }
        }
      }
    `
  );

  return (
    <Fragment>
      {/* {loader && <PreLoader />} */}
      {/* <VideoPopup /> */}
      <ImageView />
      <Header
        header={header}
        data={sanitySitesettings.header}
        topBanner={sanitySitesettings.topBanner}
      />
      {children}
      <Footer
        footer={footer}
        data={sanitySitesettings?.footer}
        news={allSanityBlog}
      />
      <ScrollTop />
    </Fragment>
  );
};
export default Layout;
