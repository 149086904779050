import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Fragment, useEffect, useState } from "react";
import useWindowSize from "../useWindowSize";
import { stickyNav } from "../utils";
import MobileHeader from "./MobileHeader";
import OffcanvasPanel from "./OffcanvasPanel";
import Top from "./Top";
import Button from "../components/Button";
import MyLink from "../components/Link";

const Header = ({ header, topBanner, data }) => {
  useEffect(() => {
    stickyNav();
  }, []);
  const [overlayPanel, setOverlayPanel] = useState(false);
  const togglePanel = () => setOverlayPanel(!overlayPanel);

  const { width } = useWindowSize();

  useEffect(() => {
    const headers = document.querySelectorAll(".header-navigation");
    headers.forEach((header) => {
      if (width <= 1199) {
        header.classList.add("breakpoint-on");
      } else {
        header.classList.remove("breakpoint-on");
      }
      // toggle
      const toggleBtn = header.getElementsByClassName("navbar-toggler")[0],
        overlay = header.getElementsByClassName("nav-overlay")[0],
        menu = header.getElementsByClassName("nav-menu")[0],
        brandLogo = header.getElementsByClassName("mobile-logo")[0];

      toggleBtn.addEventListener("click", () => {
        overlay.classList.add("active");
        menu.classList.add("menu-on");
      });
      overlay.addEventListener("click", () => {
        overlay.classList.remove("active");
        menu.classList.remove("menu-on");
      });
      brandLogo.addEventListener("click", () => {
        overlay.classList.remove("active");
        menu.classList.remove("menu-on");
      });
    });
  }, [width]);

  switch (header) {
    case 1:
      return <Header1 />;
    case 2:
      return <Header2 />;
    case 3:
      return (
        <Header3
          overlayPanel={overlayPanel}
          togglePanel={() => togglePanel()}
        />
      );
    case 4:
      return (
        <Header4
          overlayPanel={overlayPanel}
          togglePanel={() => togglePanel()}
          data={data}
          topBanner={topBanner}
        />
      );
    default:
      return <DefaultHeader data={data} topBanner={topBanner} />;
  }
};
export default Header;

const Header1 = () => (
  <header className="header-area">
    <div className="header-top-bar top-bar-one dark-black-bg">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-12 col-md-12 col-6">
            <div className="top-bar-left d-flex align-items-center">
              <span className="text">
                Welcome to Agriculture &amp; Organic Food Template
              </span>
              {/* <span className="lang-dropdown">
                <select className="wide">
                  <option value={1}>English</option>
                  <option value={2}>French</option>
                </select>
              </span> */}
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-md-12 col-6">
            <div className="top-bar-right">
              <span className="text">
                <i className="far fa-clock" />
                Opening Hours : Sunday- Friday, 08:00 am - 05:00pm
              </span>
              <ul className="social-link">
                <li>
                  <a href="#">
                    <i className="fab fa-facebook-f" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-linkedin" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-youtube" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="header-middle">
      <div className="container-1350">
        <div className="row align-items-center">
          <div className="col-xl-4 d-xl-block d-lg-none">
            <div className="site-branding d-lg-block d-none">
              <Link to="/">
                <a className="brand-logo">
                  <img src="/assets/images/logo/logo-1.png" alt="Site Logo" />
                </a>
              </Link>
            </div>
          </div>
          <div className="col-xl-8 col-lg-12">
            <div className="contact-information">
              <div className="information-item_one d-flex">
                <div className="icon">
                  <i className="flaticon-placeholder" />
                </div>
                <div className="info">
                  <h5 className="mb-1">Locations</h5>
                  <p>55 Main Street, New York</p>
                </div>
              </div>
              <div className="information-item_one d-flex">
                <div className="icon">
                  <i className="flaticon-email" />
                </div>
                <div className="info">
                  <h5 className="mb-1">Email Us</h5>
                  <p>
                    <a href="mailto:tjs@civsav.com">tjs@civsav.com</a>
                  </p>
                </div>
              </div>
              <div className="button text-md-right text-sm-center">
                <Link to="/contact">
                  <a className="main-btn btn-yellow">Get a Quote</a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="header-navigation navigation-one">
      <div className="nav-overlay" />
      <div className="container-1350">
        <div className="primary-menu">
          <div className="site-branding">
            <Link to="/">
              <a className="brand-logo">
                <img src="/assets/images/logo/logo-1.png" alt="Site Logo" />
              </a>
            </Link>
          </div>
          <div className="nav-inner-menu">
            <div className="nav-menu">
              {/*=== Mobile Logo ===*/}
              <div className="mobile-logo mb-30 d-block d-xl-none text-center">
                <Link to="/">
                  <a className="brand-logo">
                    <img src="/assets/images/logo/logo-1.png" alt="Site Logo" />
                  </a>
                </Link>
              </div>
              {/*=== Main Menu ===*/}
              <Menu />
              <MobileHeader />
            </div>
            {/*=== Nav Right Item ===*/}
            <div className="nav-right-item">
              <div className="navbar-toggler">
                <span />
                <span />
                <span />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
);

const Header2 = () => (
  <header className="header-area">
    <div className="header-top-bar top-bar-one dark-black-bg">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-12 col-md-12 col-6">
            <div className="top-bar-left d-flex align-items-center">
              <span className="text">
                Welcome to Agriculture &amp; Organic Food Template
              </span>
              {/* <span className="lang-dropdown">
                <select className="wide">
                  <option value={1}>English</option>
                  <option value={2}>French</option>
                </select>
              </span> */}
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-md-12 col-6">
            <div className="top-bar-right">
              <span className="text">
                <i className="far fa-clock" />
                Opening Hours : Sunday- Friday, 08:00 am - 05:00pm
              </span>
              <ul className="social-link">
                <li>
                  <a href="#">
                    <i className="fab fa-facebook-f" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-linkedin" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-youtube" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="header-navigation navigation-two">
      <div className="nav-overlay" />
      <div className="container-fluid">
        <div className="primary-menu">
          <div className="site-branding">
            <Link to="/">
              <a className="brand-logo">
                <img src="/assets/images/logo/logo-1.png" alt="Site Logo" />
              </a>
            </Link>
          </div>
          <div className="nav-inner-menu">
            <div className="nav-menu">
              {/*=== Mobile Logo ===*/}
              <div className="mobile-logo mb-30 d-block d-xl-none text-center">
                <Link to="/">
                  <a className="brand-logo">
                    <img src="/assets/images/logo/logo-1.png" alt="Site Logo" />
                  </a>
                </Link>
              </div>
              {/*=== Navbar Call Button ===*/}
              <div className="call-button text-center">
                <span>
                  <i className="far fa-phone" />
                  <a to="tel:+012(345)678">+012 (345) 678</a>
                </span>
              </div>
              {/*=== Main Menu ===*/}
              <Menu />
              <MobileHeader />
              {/*=== Navbar Menu Button ===*/}
              <div className="menu-button">
                <Link to="/contact">
                  <a className="main-btn btn-yellow">Get a Quote</a>
                </Link>
              </div>
            </div>
            {/*=== nav Right Item ===*/}
            <div className="nav-right-item d-flex align-items-center">
              <div className="call-button">
                <span>
                  <i className="far fa-phone" />
                  <a href="tel:+012(345)678">+012 (345) 678</a>
                </span>
              </div>
              <div className="menu-button">
                <Link to="/contact">
                  <a className="main-btn btn-yellow">Get a Quote</a>
                </Link>
              </div>
              <div className="navbar-toggler">
                <span />
                <span />
                <span />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
);

const Header3 = ({ overlayPanel, togglePanel }) => (
  <Fragment>
    <OffcanvasPanel overlyPanel={overlayPanel} togglePanel={togglePanel} />
    <header className="header-area">
      {/* Header Navigation */}
      <div className="header-navigation navigation-three">
        <div className="nav-overlay" />
        <div className="container-fluid">
          <div className="primary-menu">
            {/* Site Branding */}
            <div className="site-branding">
              <Link to="/">
                <a className="brand-logo">
                  <img src="/assets/images/logo/logo-3.png" alt="Site Logo" />
                </a>
              </Link>
              <Link to="/">
                <a className="sticky-logo">
                  <img src="/assets/images/logo/logo-1.png" alt="Site Logo" />
                </a>
              </Link>
            </div>
            {/* Nav inner Menu */}
            <div className="nav-inner-menu">
              {/* Nav Menu */}
              <div className="nav-menu">
                {/*=== Mobile Logo ===*/}
                <div className="mobile-logo mb-30 d-block d-xl-none text-center">
                  <Link to="/">
                    <a className="brand-logo">
                      <img
                        src="/assets/images/logo/logo-1.png"
                        alt="Site Logo"
                      />
                    </a>
                  </Link>
                </div>
                {/*=== Navbar Call Button ===*/}
                <div className="call-button text-center">
                  <span>
                    <i className="far fa-phone" />
                    <a href="tel:+012(345)678">+012 (345) 678</a>
                  </span>
                </div>
                {/*=== Main Menu ===*/}
                <Menu />
                <MobileHeader />
                {/*=== Navbar Menu Button ===*/}
                <div className="menu-button">
                  <Link to="/contact">
                    <a className="main-btn btn-yellow">Get a Quote</a>
                  </Link>
                </div>
              </div>
              {/*=== Nav Right Item ===*/}
              <div className="nav-right-item d-flex align-items-center">
                <div className="call-button">
                  <span>
                    <i className="far fa-phone" />
                    <a href="tel:+012(345)678">+012 (345) 678</a>
                  </span>
                </div>
                <div className="menu-button">
                  <Link to="/contact">
                    <a className="main-btn btn-yellow">Get a Quote</a>
                  </Link>
                </div>
                <div className="bar-item">
                  <a className="c-pointer" onClick={() => togglePanel()}>
                    <img src="/assets/images/bar.png" alt="" />
                  </a>
                </div>
                <div className="navbar-toggler">
                  <span />
                  <span />
                  <span />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </Fragment>
);

const Header4 = ({ overlayPanel, togglePanel }) => (
  <Fragment>
    <OffcanvasPanel overlyPanel={overlayPanel} togglePanel={togglePanel} />
    <header className="header-area">
      <div className="header-top-bar top-bar-two dark-black-bg">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-12 col-md-12 col-6">
              <div className="top-bar-left d-flex align-items-center">
                {/* <span className="lang-dropdown">
                  <select className="wide">
                    <option value={1}>English</option>
                    <option value={2}>French</option>
                  </select>
                </span> */}
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-md-12 col-6">
              <div className="top-bar-right">
                <span className="text">
                  <i className="far fa-clock" />
                  Opening Hours : Sunday- Friday, 08:00 am - 05:00pm
                </span>
                <ul className="social-link">
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-youtube" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header-navigation navigation-four">
        <div className="nav-overlay" />
        <div className="container-fluid">
          <div className="primary-menu">
            <div className="site-branding">
              <Link to="/">
                <a className="brand-logo">
                  <img src="/assets/images/logo/logo-3.png" alt="Site Logo" />
                </a>
              </Link>
              <Link to="/index-4">
                <a className="sticky-logo">
                  <img src="/assets/images/logo/logo-2.png" alt="Site Logo" />
                </a>
              </Link>
            </div>
            <div className="nav-inner-menu">
              <div className="bar-item">
                <a className="c-pointer" onClick={() => togglePanel()}>
                  <img src="/assets/images/bar-2.png" alt="" />
                </a>
              </div>
              <div className="nav-menu">
                {/*=== Mobile Logo ===*/}
                <div className="mobile-logo mb-30 d-block d-xl-none text-center">
                  <Link to="/">
                    <a className="brand-logo">
                      <img
                        src="/assets/images/logo/logo-1.png"
                        alt="Site Logo"
                      />
                    </a>
                  </Link>
                </div>
                {/*=== Navbar Call Button ===*/}
                <div className="call-button text-center">
                  <span>
                    <i className="far fa-phone" />
                    <a href="tel:+012(345)678">+012 (345) 678</a>
                  </span>
                </div>
                {/*=== Main Menu ===*/}
                <Menu />
                <MobileHeader />
                {/*=== Navbar Menu Button ===*/}
                <div className="menu-button">
                  <Link to="/contact">
                    <a className="main-btn bordered-btn">Get a Quote</a>
                  </Link>
                </div>
              </div>
              {/*=== Nav Right Item ===*/}
              <div className="nav-right-item d-flex align-items-center">
                <div className="call-button">
                  <span>
                    <i className="far fa-phone" />
                    <a href="tel:+012(345)678">+012 (345) 678</a>
                  </span>
                </div>
                <div className="menu-button">
                  <Link to="/contact">
                    <a className="main-btn bordered-btn bordered-yellow">
                      Get a Quote
                    </a>
                  </Link>
                </div>
                <div className="navbar-toggler">
                  <span />
                  <span />
                  <span />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </Fragment>
);

const DefaultHeader = ({ data, topBanner }) => (
  <header className="header-area">
    {topBanner && topBanner.enable && <Top data={topBanner} />}
    {/* Header Navigation */}
    <div className="header-navigation navigation-three">
      <div className="nav-overlay" />
      <div className="container-fluid">
        <div className="primary-menu">
          {/* Site Branding */}
          <div className="site-branding">
            <Link to="/">
              <a className="brand-logo">
                <GatsbyImage
                  image={data?.logo?.asset?.gatsbyImageData}
                  alt={data?.data?.logo?.alt}
                />
              </a>
            </Link>
            <Link to="/">
              <a className="sticky-logo">
                <GatsbyImage
                  image={data?.logo2?.asset?.gatsbyImageData}
                  alt={data?.data?.logo2?.alt}
                />
              </a>
            </Link>
          </div>
          {/* Nav inner Menu */}
          <div className="nav-inner-menu">
            {/* Nav Menu */}
            <div className="nav-menu">
              {/*=== Mobile Logo ===*/}
              <div className="mobile-logo mb-30 d-block d-xl-none text-center">
                <Link to="/">
                  <a className="brand-logo">
                    <GatsbyImage
                      image={data?.logo2?.asset?.gatsbyImageData}
                      alt={data?.data?.logo2?.alt}
                    />
                  </a>
                </Link>
              </div>
              {/*=== Navbar Call Button ===*/}
              {/* <div className="call-button text-center">
                <span>
                  <i className="far fa-phone" />
                  <a href={`tel:${data?.phone}`}>{data?.phone}</a>
                </span>
              </div> */}
              {/*=== Main Menu ===*/}
              <Menu list={data?.menu} />
              <MobileHeader list={data?.menu} />
              {/*=== Navbar Menu Button ===*/}
              <div className="menu-button">
                {data?.button?.title && (
                  <Button
                    text={data.button.title}
                    btnType={data?.button.btnType}
                    link={data?.button.link}
                    linkType={data?.button.linkType}
                  />
                )}
              </div>
            </div>
            {/*=== Nav Right Item ===*/}
            <div className="nav-right-item d-flex align-items-center">
              <div className="call-button">
                <Link to="/cart">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    fill="black"
                    class="bi bi-cart"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                  </svg>
                </Link>
                {/* <span>
                  <i className="far fa-bag" />
                  <a href={`tel:${data?.phone}`}>{data?.phone}</a>
                </span> */}
              </div>
              <div className="menu-button">
                {data?.button?.title && (
                  <Button
                    text={data.button.title}
                    btnType={data?.button.btnType}
                    link={data?.button.link}
                    linkType={data?.button.linkType}
                  />
                )}
              </div>
              {/* <div className="bar-item">
                <a href="#">
                  <img src="/assets/images/bar.png" alt="" />
                </a>
              </div> */}
              <div className="navbar-toggler">
                <span />
                <span />
                <span />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
);

const Menu = ({ list }) => (
  <nav className="main-menu d-none d-xl-block">
    <ul>
      {list?.map((item, index) => {
        return (
          <li
            className={`menu-item ${
              item.childMenu.length > 0 ? "has-children" : ""
            } `}
            key={index}
          >
            {item.childMenu.length > 0 ? (
              <>
                <a href="#" className="active">
                  {item.parent?.label}
                </a>
                <ul className="sub-menu">
                  {item.childMenu.map((child, ind) => (
                    <li key={ind}>
                      <MyLink
                        link={child.link}
                        linkType={child.type}
                        text={child.label}
                      />
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <MyLink
                link={item.parent.link}
                linkType={item.parent.type}
                text={item.parent.label}
              />
            )}
          </li>
        );
      })}

      {/* <li>
        <Link to="/about">About</Link>
      </li>
      <li className="menu-item">
        <Link to="/services">Services</Link>
      </li>
      <li className="menu-item">
        <Link to="/portfolio">Portfolio</Link>
      </li>
      <li className="menu-item has-children">
        <a href="#">Shop</a>
        <ul className="sub-menu">
          <li>
            <Link to="/products">Our Products</Link>
          </li>
          <li>
            <Link to="/products-left-sidebar">Product Left Sidebar</Link>
          </li>
          <li>
            <Link to="/products-right-sidebar">Product Right Sidebar</Link>
          </li>
          <li>
            <Link to="/product-details">Product Details</Link>
          </li>
          <li>
            <Link to="/cart">Cart</Link>
          </li>
          <li>
            <Link to="/checkout">Checkout</Link>
          </li>
        </ul>
      </li>
      <li className="menu-item">
        <Link href="/blog">Blog</Link>
      </li>
      <li className="menu-item has-children">
        <a href="#">Pages</a>
        <ul className="sub-menu">
          <li>
            <Link to="/farmers">Our Farmers</Link>
          </li>
          <li>
            <Link to="/faqs">Faq</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </li> */}
    </ul>
  </nav>
);
