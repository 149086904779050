import React from "react";
import { useState } from "react";
import MyLink from "../components/Link";

const MobileHeader = ({ list }) => {
  const [activeMenu, setActiveMenu] = useState("");

  const activeMenuSet = (value) =>
      setActiveMenu(activeMenu === value ? "" : value),
    activeLi = (value) =>
      value === activeMenu ? { display: "block" } : { display: "none" };

  const toggleMenu = () => {
    const menu = document.getElementsByClassName("nav-menu")[0];
    const overlay = document.getElementsByClassName("nav-overlay")[0];

    overlay.classList.remove("active");
    menu.classList.remove("menu-on");
  };
  return (
    <nav className="main-menu d-block d-xl-none">
      <ul>
        {list?.map((item, index) => {
          return (
            <li
              className={`menu-item ${
                item.childMenu.length > 0 ? "has-children" : ""
              } `}
              key={index}
            >
              {item.childMenu.length > 0 ? (
                <>
                  <a href="#" className="active">
                    {item.parent?.label}
                  </a>
                  <ul
                    className="sub-menu"
                    style={activeLi(`${item.parent.label}`)}
                  >
                    {item.childMenu.map((child, ind) => (
                      <li key={ind}>
                        <MyLink
                          link={child.link}
                          linkType={child.type}
                          text={child.label}
                          onClick={toggleMenu}
                        />
                      </li>
                    ))}
                  </ul>
                  <span
                    className="dd-trigger"
                    onClick={() => activeMenuSet(`${item.parent.label}`)}
                  >
                    <i className="far fa-angle-down" />
                  </span>
                </>
              ) : (
                <MyLink
                  link={item.parent.link}
                  linkType={item.parent.type}
                  text={item.parent.label}
                  onClick={toggleMenu}
                />
              )}
            </li>
          );
        })}
        {/* <li className="menu-item has-children">
          <a href="#" className="active">
            Home
          </a>
          <ul className="sub-menu" style={activeLi("home")}>
            <li>
              <Link to="/">Home Agriculture</Link>
            </li>
            <li>
              <Link to="/index-2">Home Organic Food</Link>
            </li>
            <li>
              <Link to="/index-3">Home Farming</Link>
            </li>
            <li>
              <Link to="/index-4">Home Dairy Farm</Link>
            </li>
          </ul>
          <span className="dd-trigger" onClick={() => activeMenuSet("home")}>
            <i className="far fa-angle-down" />
          </span>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li className="menu-item has-children">
          <a href="#">Services</a>
          <ul className="sub-menu" style={activeLi("Services")}>
            <li>
              <Link to="/services">Our Services</Link>
            </li>
            <li>
              <Link to="/service-details">Service Details</Link>
            </li>
          </ul>
          <span
            className="dd-trigger"
            onClick={() => activeMenuSet("Services")}
          >
            <i className="far fa-angle-down" />
          </span>
        </li>
        <li className="menu-item has-children">
          <a href="#">Portfolio</a>
          <ul className="sub-menu" style={activeLi("Portfolio")}>
            <li>
              <Link to="portfolio-grid">Portfolio Grid</Link>
            </li>
            <li>
              <Link to="portfolio-full-width">Portfolio Full Width</Link>
            </li>
            <li>
              <Link to="portfolio-details">Portfolio Details</Link>
            </li>
          </ul>
          <span
            className="dd-trigger"
            onClick={() => activeMenuSet("Portfolio")}
          >
            <i className="far fa-angle-down" />
          </span>
        </li>
        <li className="menu-item has-children">
          <a href="#">Shop</a>
          <ul className="sub-menu" style={activeLi("Shop")}>
            <li>
              <Link to="products">Our Products</Link>
            </li>
            <li>
              <Link to="products-left-sidebar">Product Left Sidebar</Link>
            </li>
            <li>
              <Link to="products-right-sidebar">Product Right Sidebar</Link>
            </li>
            <li>
              <Link to="product-details">Product Details</Link>
            </li>
            <li>
              <Link to="cart">Cart</Link>
            </li>
            <li>
              <Link to="checkout">Checkout</Link>
            </li>
          </ul>
          <span className="dd-trigger" onClick={() => activeMenuSet("Shop")}>
            <i className="far fa-angle-down" />
          </span>
        </li>
        <li className="menu-item has-children">
          <a href="#">Blog</a>
          <ul className="sub-menu" style={activeLi("Blog")}>
            <li>
              <Link to="blog-standard">Blog Standard</Link>
            </li>
            <li>
              <Link to="blog-details">Blog Details</Link>
            </li>
          </ul>
          <span className="dd-trigger" onClick={() => activeMenuSet("Blog")}>
            <i className="far fa-angle-down" />
          </span>
        </li>
        <li className="menu-item has-children">
          <a href="#">Pages</a>
          <ul className="sub-menu" style={activeLi("Pages")}>
            <li>
              <Link to="farmers">Our Farmers</Link>
            </li>
            <li>
              <Link to="faqs">Faq</Link>
            </li>
            <li>
              <Link to="contact">Contact</Link>
            </li>
          </ul>
          <span className="dd-trigger" onClick={() => activeMenuSet("Pages")}>
            <i className="far fa-angle-down" />
          </span>
        </li> */}
      </ul>
    </nav>
  );
};
export default MobileHeader;
